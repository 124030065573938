import classes from "./agreementPageGenProvisions.module.css";

function AgreementPageGenProvisions() {
  return (
    <div className={classes.AgreementPageGenProvisions}>
      <h2 className={classes.genProvisionsHeading}> 第一章 総 則 </h2>

      <div>
        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>
            第 1 条（利用規定の適用）
          </h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                本利用規定は、株式会社モバイルヴィレッジ（以下、｢当社｣といいます）が提供する「スカイセブンモバイルの商号で提供する移動体通信端末及び移動体通信サービス」（以下、「本商品」といいます）の利用条件について定めるものです。本商品のご利用にあたっては、本利用規定をよくお読みのうえ、ご理解いただいたうえでお使いください。本商品の利用開始をもって、本利用規定に同意したものとみなします。
              </li>
              <li>
                利用者（契約者のほか、契約者以外に利用者が存在する場合の当該利用者を含むものとし、以下、同様とします）が本商品を利用するにあたっては、本利用規定が適用されます（ただし、契約者のみに適用される条項についてはこの限りではありません）。
              </li>
              <li>
                当社は、本利用規定を当社が運営する Web サイト（以下、「当社 Web
                サイト」といいます）に掲載する方法により、利用者に周知します。
              </li>
              <li>
                当社は本利用規定を変更することがあります。この場合、本商品の利用条件は変更後の利用規定によります。
              </li>
              <li>
                当社が本利用規定を変更する場合は、当社 Web
                サイトにおいて、利用規定を変更する旨、変更後の利用規定の内容および変更の効力発生日を周知します。
              </li>
            </ol>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>
            第 2 条（本商品の内容）
          </h3>
          <div className={`${classes.articleTwo} ${classes.agreementDetail}`}>
            <p>当社が本商品において提供するサービスは、次のとおりとします。</p>
            <div>
              <p>
                <span>基本サービス</span>
              </p>
              <p>
                基本サービス 第4
                世代携帯電話ネットワークまたは第３世代携帯電話ネットワークによるデータ通信サービスおよび音声サービスを利用できるサービス
                （スカイセブンモバイルSIM 選べるパケットかけ放題プラン
              </p>
              <p>
                <span>オプションサービス</span>
              </p>
              <table className={classes.genProvisionTableOne}>
                <tbody>
                  <tr>
                    <td>音声オプションサービス</td>
                    <td className={classes.firstDominantWidth}>
                      音声サービスの付加的な機能を提供するオプションサービス
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>第 3 条（用語の定義）</h3>
          <div className={classes.agreementDetail}>
            <p className={classes.termDefinitionsIntroText}>
              本利用規定において使用する用語の意味は、次の通りとします。
            </p>
            <table className={classes.termDefinitionsTable}>
              <thead>
                <tr>
                  <td>用語</td>
                  <td>用語の意味</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>携帯電話事業者</td>
                  <td>
                    当社とデータ通信サービスまたは音声サービスの提供にかかる相互接続協定その他の契約を締結している携帯電話事業者
                  </td>
                </tr>
                <tr>
                  <td>データ通信サービス</td>
                  <td>
                    携帯電話事業者の通信網を用いて当社が提供する無線データ通信サービス
                  </td>
                </tr>
                <tr>
                  <td>音声通話サービス</td>
                  <td>
                    携帯電話事業者の電気通信回線設備を用いて、音声通信を行うサービス
                  </td>
                </tr>
                <tr>
                  <td>
                    SMS（ショートメッ
                    <br />
                    セージサービス）
                  </td>
                  <td>
                    携帯電話事業者の電気通信回線設備を用いて、テキストメッセージの送受信を行うサービス
                  </td>
                </tr>
                <tr>
                  <td>音声サービス</td>
                  <td>音声通話サービスおよび SMS の総称</td>
                </tr>
                <tr>
                  <td>音声オプションサービス</td>
                  <td>音声サービスに関するオプションサービス</td>
                </tr>
                <tr>
                  <td>料金月</td>
                  <td>
                    開通日を起算日とし、1
                    の暦月における起算日（該当日がない場合は当該暦月の末日とします。
                    以下、同様とします）から次の暦月における起算日の前日までの期間
                  </td>
                </tr>
                <tr>
                  <td>ユニバーサルサービス料</td>
                  <td>
                    電気通信事業法（昭和 59 年法律第 86
                    号）に定める基礎的電気通信役務の提供の確保のための負担金に充てるために、基礎的電気通信役務の提供に係る交付金及び負担金算定等規則（平成14
                    年総務省令第 64
                    号）により算出された額に基づいて、当社が定める料金
                  </td>
                </tr>
                <tr>
                  <td>端末機器</td>
                  <td>
                    端末機器の技術基準適合認定等に関する規則（平成 16
                    年総務省令第 15 号）で定める種類の端末設備の機器
                  </td>
                </tr>
                <tr>
                  <td>当社端末機器</td>
                  <td>本商品（SIM商品を除きます）に含まれている端末機器</td>
                </tr>
                <tr>
                  <td>自営端末機器</td>
                  <td>利用者が SIM 商品を利用するため自ら用意する端末機器</td>
                </tr>
                <tr>
                  <td>SIM 商品</td>
                  <td>利用者が自ら端末機器を用意する商品</td>
                </tr>
                <tr>
                  <td>SIM カード</td>
                  <td>
                    契約者識別番号その他の情報を記憶することができるICカードであって、本商品の提供にあたり当社から貸与されるもの
                  </td>
                </tr>
                <tr>
                  <td>協定事業者</td>
                  <td>
                    当社と相互接続協定その他の契約を締結している電気通信事業者（携帯電話事業者を含みます）
                  </td>
                </tr>
                <tr>
                  <td>国際電気通信事業者等</td>
                  <td>
                    携帯電話事業者との間で相互接続協定を締結して国際電話サービス等を提供する電気通信事業者
                  </td>
                </tr>
                <tr>
                  <td>消費税相当額</td>
                  <td>
                    {" "}
                    消費税法（昭和 63 年法律第 108
                    号）および同法に関する法令の規定に基づき課税される消費税の額、ならびに、地方税法（昭和
                    25 年法律第 226
                    号）および同法に関する法令の規定に基づき課税される消費税の額
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>
            第 4 条（商品内容の変更）
          </h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                当社は、本商品の内容または名称を予告なく変更することがあります。
              </li>
              <li>
                前項の変更がある場合には、当社 Web サイトにおいて告知します。
              </li>
            </ol>
          </div>
        </div>
        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>
            第 5 条（当社からの告知）
          </h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                当社が必要と判断した場合、当社は、本商品のご利用に関して必要となる事項を、当社Web
                サイトにおいて随時告知します。
              </li>
              <li>
                当社が必要と判断した場合、当社は、本商品のご利用に関して必要となる事項を、ユーザー登録をしている利用者、および第
                6
                条（申込み）に基づき契約者情報を登録した利用者に対し、そのユーザー登録情報または契約者情報において登録された連絡先宛てに個別に通知することがあります。
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AgreementPageGenProvisions;
