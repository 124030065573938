import classes from "./ContractStartEnd.module.css";

function ContractStartEnd() {
  return (
    <div className={classes.ContractStartEndContainer}>
      <h2 className={classes.contractStartEndHeading}>
        第二章 利用の開始および終了
      </h2>
      <p>第1節 基本サービス</p>
      <div>
        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>第 6 条（申込み） </h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                本商品の利用を希望する方（以下、本節において「申込者」といいます）は、本利用規定に同意したうえで、当社所定の方法により申込みを行うものとします。
              </li>
              <li>
                申込者は、本条第 1
                項の申込みにあたり、当社所定の契約者情報および/または本人確認書類の写しを提供するものとします。なお、当社が、申込者から提供された本人確認書類の写しの内容を確認する必要があると判断した場合、当社は、当該本人確認書類に関する利用者情報を警察機関その他の行政機関に提供することがあります。
              </li>
              <li>
                申込者は、本商品を18
                歳未満の方に使用させようとする場合は、本条第1
                項の申込みにあたり、その旨を申し出るものとします。
              </li>
              <li>
                当社は、次の場合には、本商品の利用申込みを承諾しないことがあります
                <ol>
                  <li>
                    申込内容に記入もれ、誤記、または虚偽の記載があるとき。{" "}
                  </li>
                  <li>
                    申込者が料金その他の債務の支払いを現に怠り、または怠るおそれがあるとき。
                  </li>
                  <li>
                    申込者が合理的な理由なく本商品の受領を怠り、または故意に受領を遅らせる等の事実があったとき。
                  </li>
                  <li>
                    申込者が過去に本利用規定に違反し、または現に違反しているとき。
                  </li>
                  <li>
                    申込者の当社との間の契約回線数が、当社が定める数を超えることとなるとき。
                  </li>
                  <li>
                    その他、当社の業務の遂行上著しい支障があると判断するとき。
                  </li>
                </ol>
              </li>
              <li>
                本商品の利用契約は、当社が利用契約の申込みを承諾し、当社所定の手続きを完了した日に成立するものとします。
              </li>
              <li>
                申込者は、本商品を申込む場合において、番号ポータビリティ（携帯電話番号を変更することなく、携帯電話サービスを受ける電気通信事業者を変更することをいいます。以下、同様とします）の適用を希望するときは、当社所定の方法によりその旨を申し出るものとします。
              </li>
              <li>
                ご利用者様（以下、甲とする）と株式会社モバイルヴィレッジ（以下、乙とする）の間で携帯電話回線及び端末及び音声・データSIMカードの貸与につき下記のとおり契約する。
                <ol>
                  <li>
                    ご契約期間は、ご利用開始月を含め翌月から１２ヶ月間を最低利用期間とする。甲乙の申し出がない限り自動継続とし本契約書の効力は継続します。解約の受付は解約月の当月毎月２５日１７時までに乙へ連絡をするものとし、原則月末の２３時５９分に解約となる。土日祝日が２５日の場合も同様とします。
                  </li>
                  <li>
                    ご利用開始月を含め上記記載の(1)契約最低期間以外で解約となった場合は、いかなる理由でも解約事務手数料として、１カ月分のプラン代金に相当する額を直ちに甲は乙に支払うものとする。解約の際、リース端末がある場合は、更新月や更新月以外でも甲は乙へ速やかに返却するものとする。乙の指定する期日までに返却しない場合は、甲は乙へ甲指定の金員を支払うものとする。
                  </li>
                  <li>
                    本契約の解約について、上記記載加(1)の最低利用期間後の解約は、解約事務手数料は免除されるものとする。但し、最低利用期間を超えた場合でも、甲より乙へ解約の連絡がなく、かつ基本料金の支払いがなく、強制解約となった場合は、解約月までの基本料金及び解約事務手数料として１カ月分のプラン代金に相当する額が発生するものとする。
                    尚、解約事務手数料の１カ月分のプラン代金に相当する金額の定義としては、原則は解約となった月とする。
                  </li>
                  <li>
                    甲は乙に対し、契約の利用料金を当月の２７日（土日祝日の場合は、その翌平日）に口座振替にて支払う。口座振替出来ない場合は、乙に事前に連絡し、振り込み及び持参にて支払うものとする。当月の利用料金が振替出来ない場合、甲は乙へ必ず連絡するものとし、乙の許諾がある場合は回線を一時停止せず、翌月の５日まで待つものとする。口座振替払いが出来ない場合、３日営業日までに乙へ連絡がない場合は、乙は甲の回線を即時停止するものとする。翌月５日の１５時をもっても入金(不足金がある場合も含む)が無き場合は、いかなる理由があっても回線を一時停止する。
                    <span>
                      <span></span>
                    </span>
                  </li>
                  <li>
                    甲は乙提供の携帯電話端末及びタブレットその他通信機器及に初期不良などの理由がない限りは意義を問わないもとする。また端末の操作方法やアプリの使用方法について、乙は甲に対し一切の関与をしないものとする。
                  </li>
                  <li>
                    甲に下記の事由があるときは，乙はいつでも本契約を解約でき、未使用分の返金なども一切行いません。
                    <ul>
                      <li>
                        乙が甲に連絡した際、3日以上連絡が取れなかった場合や入金の確認（振替払いが出来なかった場合）が取れなかったとき。
                      </li>
                      <li>
                        甲が口座振替払いのWEB口座登録や振替依頼用紙等に不備があった場合、乙が催促の連絡をしたにもかかわらず迅速な対応をしなかった場合や不備があった場合の対応に協力しないとき
                      </li>
                      <li>
                        乙が甲に対し申告した本人確認の情報に虚偽の申請や記載があったとき
                      </li>
                      <li>
                        端末を含むSIMカードを第三者へ許可なく貸与・譲渡したとき
                      </li>
                      <li>リース端末の返却がないとき</li>
                      <li>
                        その他甲が公序良俗に著しく反する等、乙が相応しくないと判断した場合や言動や行動が横暴なとき
                      </li>
                      <li>
                        端末及びSIMカードを紛失したにもかかわらず３日以上連絡しないとき
                      </li>
                    </ul>
                  </li>
                  <li>
                    甲は、申し込みの契約時に、初期費用として、事務手数料及び翌月利用料金（但し、契約日が１日の場合のみ当月の利用料金とする。）及び諸費用（振込手数料や郵送の場合には送料及び設定費用等）を乙に支払うものとし利用を開始する。但し、当月の基本料金及びオプションの日割り金額が3,300円(税込)以内の場合は契約時に合わせて支払うものとする。口座登録及び口座がない場合は、支払管理手数料として、甲は乙に5,500円税込を契約時に支払うものとする。
                  </li>
                  <li>
                    パケットプラン及びオプションの変更は当月の２５日(土・日・祝日の場合も同様とする）までとし、以降のプラン変更は翌月は出来ないことを甲は了承する。
                  </li>
                  <li>
                    甲の通信及び通信機器の使用方法に違法の可能性がある場合、または違法性を認定された時、乙は該当契約を解除し、サービスを停止し未使用分の返金などを行いません。その他の行為等も悪質と判断した場合は公官庁へ届け出るものとします。（スパム行為や携帯電話不正利用防止法に抵触する場合を含め全般に属する）
                  </li>
                  <li>
                    本契約の権利を甲及び乙は第三者に譲渡することはできません。
                    但し令状を持参した官公庁の指示に対してはこの限りではありません。
                  </li>
                  <li>
                    警察署より捜査関係事項照会書の要請があった場合、甲に該当した場合を含め乙は警察署の照会書のとおりの協力を行うことを甲は了承する。
                  </li>
                  <li>
                    甲は、住居地や連絡先が変わった場合は、すみやかに乙へ連絡するものとし、新住居に関連した身分証明書類等の写しをメールまたは写しを送付するものとする。
                  </li>
                  <li>
                    著しい通話の発信回数及び長期通話時間をした場合は、乙の判断により回線を停止する場合があることを甲は了承する。機械的発信や他のお客様への通信通話に影響が出たと判断した場合も含む。上記(13)により、乙の判断により利用料金として３０秒あたり２０.２円(税込)の料金を乙は甲へ請求するものとします。
                  </li>
                  <li>
                    前項につき、乙が甲に対し、２度の警告をメール等にて行ったにもかかわらず、改善されない場合、または連絡等がない場合は、乙の判断により、回線停止及び解約ができるものとすることを、甲は了承する。
                  </li>
                  <li>
                    有料通話(104/0570/電報/その他の有料通話)やショートメールを利用した場合は、乙の請求によりすみやかに支払うものとする。利用月の翌月もしくは翌々月に請求となります。
                  </li>
                  <li>
                    SIMカード紛失の際は、回線の利用を一時停止するものとし、再開の際は事務手数料として金5,500円(内訳：1,100円及びSIMカード再交付代金4,400円)を事前に再開までに支払うものとする（いずれも税込）
                  </li>
                  <li>
                    甲による端末のパスコードロックによる、PUKコードの発行費として、甲は乙に対し発行手数料として事前に1,100円振り込むものとする。（着金確認後にPUKコードを申請し、発行までに２～３日必要であることを了承する。）また、PUKコードの入力の誤り等にて、SIMカードが無効となった場合は、甲は乙にSIMカード再発行を依頼することができる。
                    その場合、事前にSIM再発行手数料として5,500円を支払うものとする。送料がかかる場合はその実費も含めるものとする。
                  </li>
                  <li>
                    乙は、甲とのご契約内容及び個人（法人）情報の漏洩に細心の注意をはらい管理監督いたします。司法による認定が行われない限り甲、又は第三者からの責任の追及や損害賠償に応じません
                  </li>
                  <li>
                    甲は貸与えられた通信端末機器の操作説明及び機能説明については、初期設定を除き、甲自身でWEB上などのメーカーの説明書などを閲覧し調べるものとする。
                  </li>
                  <li>
                    甲は､自ら通信端末機器を機種変更した場合、初期設定(SIMカードのセットアップ及び通信のAPN設定やアカウントの移行等は自ら行うものとする。万が一開封及び設定を変更した場合に発生した損害は甲の責任とする。上記初期設定等を乙及び乙の代理店へ依頼した場合は、所定の事務手数料が発生し、甲は乙へ支払うものとする。
                  </li>
                  <li>
                    乙が回線契約している提供元会社がやむをえない事情により乙が甲に対しサービスの提供が出来ない状況となった場合は１カ月前に甲に通知し、契約終了を伝えるものとし清算する。
                  </li>
                  <li>
                    乙が回線契約している提供元会社によるトラブルにより甲の端末回線の利用が出来ない場合に、48時間を超えた場合の損害金として当月利用プラン代の3％を損害金として、翌月の利用料金より相殺するものとする。２日間以上利用できない場合は甲乙協議の上、乙は甲に対し返金等の対応を行うものとする。その余は甲は乙に対し請求しない。４８時間以内に復旧した場合は、これを要しないことに甲は同意する。
                  </li>
                  <li>
                    甲の都合による振替口座の変更をする場合、口座変更手数料として甲は５５０円及び送金手数料を負担するものとする。
                  </li>
                  <li>
                    甲がナンバーポータビリティ(MNP予約番号の発行)を乙へ依頼する場合、総務省のガイドラインに基づき、コールセンターへの電話連絡または対面での依頼の際は、事務手数料として1,100円を支払うことを了承する。但し、メールでの（help@sky7mobile.ne.jp）依頼の場合は、事務手数料は発生しない。
                  </li>
                  <li>
                    甲及び乙は、本契約に関して裁判上の紛争が生じた場合、大阪簡易（地方）裁判所を第一審の専属的合意管轄裁判所とすることに合意します。
                  </li>
                  <li>
                    乙は携帯電話不正利用防止法にもとづき、乙が指定した「契約者の本人確認書類等」により契約者ご本人であることの確認および本人確認の記録を行います（本人確認書類等の写しは返却されないことを甲は承知する）
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>
            第 7 条（初期契約解除）
          </h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                本商品の利用者は、本商品の契約書面（電気通信事業法第 26 条の2
                に基づき、利用契約を締結したときに当社が利用者に交付する書面を指します）を受領した日から起算し
                て 8
                日が経過するまでの間、当社に書面または当社所定の方法で通知することにより、本商品の利用契約の解除（以下、「初期契約解除」といいます）を行うことができます。
              </li>
              <li>
                本商品の利用者（新規の携帯電話番号で本商品の利用契約を締結した利用者を除きます）は、前項に定める初期契約解除の通知にあたり、当社に番号ポータビリティを申込むことができます。なお、番号ポータビリティを実施するための予約番号の有効期間内に番号ポータビリティが完了しなかった場合は、初期契約解除の効力は失われるものとします。
              </li>
              <li>
                初期契約解除が行われた場合、本商品の利用契約は、利用者が初期契約解除の通知を発した日に終了します。ただし、初期契約解除において番号ポータビリティの申込みをした場合は、本商品の利用契約は、番号ポータビリティが完了した日（利用者が移転先の電気通信事業者と契約を締結した日）に終了します。
              </li>
              <li>
                初期契約解除が行われた場合、利用者は、初期契約解除までの期間において提供を受けた電気通信役務に対して利用者が支払うべき金額等として、第
                13条（初期契約解除に伴い利用者が支払う料金）に定める料金を負担するものとします。
              </li>
              <li>
                購入された端末【スマートフォン本体及びポケットWI-FI等】は初期契約解除に該当されず、初期不良以外での返金または交換は出来ません。【総務省ガイドラインより】
              </li>
            </ol>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>
            第 8 条（利用者による解約及びナンバーポータビリティについて）
          </h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                ご契約期間は、ご利用開始月を含め翌月から１２ヶ月間を最低利用期間とする。甲乙の申し出がない限り自動継続とし本契約書の効力は継続します。解約の受付は解約月の当月毎月２５日１７時までに乙へ連絡をするものとし、原則月末の２３時５９分に解約となる。土日祝日が２５日の場合も同様とします。
              </li>
              <li>
                ご利用開始月を含め上記記載の(1)契約最低期間以外で解約となった場合は、いかなる理由でも解約事務手数料として、１カ月分のプラン代金に相当する額を直ちに甲は乙に支払うものとする。解約の際、リース端末がある場合は、更新月や更新月以外でも甲は乙へ速やかに返却するものとする。乙の指定する期日までに返却しない場合は、甲は乙へ甲指定の金員を支払うものとする。
              </li>
              <li>
                本契約の解約について、上記記載加(1)の最低利用期間後の解約は、解約事務手数料は免除されるものとする。但し、最低利用期間を超えた場合でも、甲より乙へ解約の連絡がなく、かつ基本料金の支払いがなく、強制解約となった場合は、解約月までの基本料金及び解約事務手数料として１カ月分のプラン代金に相当する額が発生するものとする。
                尚、解約事務手数料の１カ月分のプラン代金に相当する金額の定義としては、原則は解約となった月とする。
              </li>
              <li>
                利用者は、本商品の利用契約の解約を希望する場合、当社所定の方法により解約申込みを行うものとします。
              </li>
              <li>
                本商品の利用者が、番号ポータビリティによる利用契約の解約を希望する場合、前項に定める解約申込みに代えて、当社所定の方法により番号ポータビリティの申込みを行うものとします。ただし、利用者が第
                33条（利用の停止）第1
                項の定めにより当該商品の利用を停止されている場合は、当社は、当該利用者による番号ポータビリティの申込みを制限することがあります。なお、番号ポータビリティを実施するための予約番号の有効期間内に番号ポータビリティが完了しなかった場合は、番号ポータビリティの申込みの効力は失われるものとします。
              </li>
              <li>
                本条第 1
                項に定める解約申込みが行われた場合、利用契約は、解約申込み日の属する料金月（各商品の仕様により異なり、以下、同様とします）の末日に終了します。なお、当社の解約手続きの都合上、終了日翌日の一定時間内において本商品を利用できる場
                合があり、この日に利用があった場合は、利用契約の終了日は同日に変更となります。この場合、終了日の属する料金月の月額基本料およびユニバーサルサービス料（以下、総称して「月額基本料等」といいます）は発生しませんが、終了日当日の利用分は通話料（SMS
                通信料を含みます。以下、あわせて「通話料等」といいます）としてお支払いいただきます。
              </li>
              <li>
                利用契約の解約にあたり、本条第 2
                項に定める番号ポータビリティの申込みが行われた場合、利用契約は、番号ポータビリティが完了した日（利用者が移転先の電気通信事業者と契約を締結した日）に終了します。この場合、終了日が料金月の途中であっても、月額基本料等について日割計算は行いません。
              </li>
            </ol>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <p className={classes.agreementMainPointIntroText}>
            第2節 オプションサービス
          </p>
          <h3 className={classes.agreementMainPoint}>
            第 9 条（音声オプションサービス）
          </h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                音声オプションサービスは、当社所定の方法により申込みを行うものとします。当社は、申込みが行われた当日から起算して当社所定の期間内に音声オプションサービスの利用登録を完了します。
              </li>
              <li>
                音声オプションサービスを解約する場合も前項と同様とします。
              </li>
              <li>
                音声オプションサービスは、基本サービスの利用終了によって終了します。
              </li>
              <li>
                音声オプションサービスの内容、料金およびその他詳細は、当社 Web
                サイトにおいて定めるものとします。
              </li>
            </ol>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <p className={classes.agreementMainPointIntroText}>
            第3節 利用権の譲渡（名義変更）
          </p>
          <h3 className={classes.agreementMainPoint}>
            第 10 条（利用権の譲渡（名義変更））
          </h3>
          <div className={classes.agreementDetail}>
            <p>
              本商品の利用者は、利用権を譲渡することはできず、当社に名義変更を請求することはできません。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ContractStartEnd;
