import { useDispatch, useSelector } from "react-redux";
import { orderActions } from "../../store/orderSlice";
import classes from "./applicationType.module.css";

function ApplicationType({ onComplete }) {
  const mainApplicationType = useSelector(
    (state) => state.order.mainApplicationType
  );
  // const headOffice =
  //   useSelector((state) => state.order.shopCode) === "00000001";

  const dispatch = useDispatch();

  const setApplicationMethod = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "wifi") {
      dispatch(orderActions.setApplicationMethod(selectedValue));
      dispatch(
        orderActions.setSelectedBundle({
          amountPlusTax: 20600,
          bundleSize: 100,
        })
      );
      dispatch(orderActions.setOrderPlan("ポケットWifi"));
    } else {
      dispatch(orderActions.setApplicationMethod(selectedValue));
    }
  };

  return (
    <div className={classes.applicationGeneralContainer}>
      <div className={classes.applicationContainer}>
        <p className={classes.applicationContainerHeading}>お申込み種別</p>
        <hr />
        <div className={classes.applicationDeliveryMode}>
          <label>
            <input
              type="radio"
              name="applicationType"
              value="新規申込"
              checked={mainApplicationType === "新規申込" ? true : false}
              className={classes.mainApplicationType}
              onChange={setApplicationMethod}
            />
            新規申込 （音声及びSMSデータプラン）
          </label>

          <label>
            <input
              type="radio"
              name="applicationType"
              value="新プラン"
              checked={mainApplicationType === "新プラン" ? true : false}
              className={classes.mainApplicationType}
              onChange={setApplicationMethod}
            />
            新プラン乗り換え(MNP)
          </label>

          {/* {headOffice && ( */}
          <label>
            <input
              type="radio"
              name="applicationType"
              value="他社からの乗り換え(MNP)"
              checked={
                mainApplicationType === "他社からの乗り換え(MNP)" ? true : false
              }
              className={classes.mainApplicationType}
              onChange={setApplicationMethod}
            />
            他社からの乗り換え(MNP)
          </label>
          {/* )} */}
          {/* {headOffice && ( */}
          <label>
            <input
              type="radio"
              name="applicationType"
              value="wifi"
              checked={mainApplicationType === "wifi" ? true : false}
              className={classes.mainApplicationType}
              onChange={setApplicationMethod}
            />
            スカイセブンのWI-FI
          </label>
          {/* )} */}
        </div>
      </div>
    </div>
  );
}
export default ApplicationType;
