import React, { useEffect, useRef } from "react";

import { useDispatch } from "react-redux";
import { orderActions } from "../store/orderSlice";
import classes from "./SuspensionAndCancellation.module.css";

function ExtraInfo() {
  const dispatch = useDispatch();
  let lastElement = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        dispatch(orderActions.termsRead());
      }
    });
    observer.observe(lastElement.current);
  }, [dispatch]);

  return (
    <div>
      <div className={classes.agreementDetail}>
        <ol>
          <li>
            当社は、次のいずれかに該当する場合には、本商品の利用を中断することがあります。
            <ol>
              <li>当社の電気通信設備の保守上または工事上やむを得ないとき。</li>
              <li>
                第40条(通信利用の制限)または第41条(通信時間等の制限)により通信利用を制限するとき。
              </li>
              <li>協定事業者の規定により通信利用を制限するとき。</li>
            </ol>
          </li>
          <li>
            当社は、前項の規定により本商品の利用を中断するときは、第5条(当社からの告知)によりあらかじめその旨を利用者に通知します。ただし、緊急やむを得ない場合はこの限りではありません。
          </li>
          <li>
            本条に基づく利用の中断があっても、本商品の利用料金(月額基本料等および音声オプションサービス料)は発生します。
          </li>
          <li>
            当社は、本条に基づく利用の中断について、本商品の料金の全部または一部の返金または損害賠償は行いません。{" "}
          </li>
        </ol>

        <div className={classes.agreementMainPoint}>
          第32条(利用者からの請求による利用の一時中断)
        </div>
        <ol>
          <li>
            当社は、利用者から当社所定の方法により請求があったときは、本商品の利用の一時中断(その電話番号を他に転用することなく一時的に利用できないようにすることをいいます。以下、同様とします)を行います。
          </li>
          <li>
            前項に基づき利用の一時中断を受けた利用者が利用の一時中断の解除を請求する場合は、当社所定の方法により行うものとします。
          </li>
          <li>
            利用の一時中断および利用の一時中断の解除の手続きは、請求を受け付けてから一定時間経過後に完了します。利用の一時中断の請求後、手続き完了までに生じた利用料金等(本商品の通話料等)は、利用者による利用であるか否かにかかわらず、利用者の負担とします。
          </li>
          <li>
            当社は、前項の規定により利用の一時中断または利用の一時中断の解除の手続きが完了したときは、第5条(当社からの告知)第2項によりその旨を利用者に通知します。
          </li>
          <li>
            利用の一時中断があっても、本商品の利用料金(月額基本料等および音声オプションサービス料)は発生します。
          </li>
        </ol>

        <div className={classes.agreementMainPoint}>第33条(利用の停止)</div>
        <ol>
          <li>
            当社は、商品の仕様として定める場合の他、利用者が次のいずれかに該当するときは、当社が定める期間、本商品および利用者が契約している他の商品の利用を停止することがあります。
            <ol>
              <li>
                商品の料金その他の債務について、支払期日を経過してもなお支払わないとき(当社が定める方法による支払いのないとき、および、支払期日経過後に支払われ当社がその支払の事実を確認できないときを含みます)。
              </li>
              <li>
                申込みが必要な商品について、申込みの内容が事実に反することが判明したとき。
              </li>
              <li>
                第14条(料金の支払方法等)第3項に定める与信枠の設定ができないとき。
              </li>
              <li>
                第22条(氏名等の変更の届出)の規定に違反したとき、または、当該規定により届け出られた内容が事実に反することが判明したとき。
              </li>
              <li>
                第29条(利用者情報の取扱い)第5項に定める契約者確認に応じないとき。
              </li>
              <li>
                第37条(自営端末機器)の規定に違反し、SIM商品を技術基準に適合しない自営端末機器で利用したとき。
              </li>
              <li>
                上記のほか、本利用規定または当社が利用者に適用する他の利用規定で禁止する行為またはそのおそれのある行為が行われたとき。
              </li>
              <li>
                商品が他の利用者に重大な支障を与える態様で使用されたとき。
              </li>
              <li>商品が違法な態様で使用されたとき。</li>
              <li>商品を長期間(1年以上)利用しなかったとき。</li>
              <li>
                当社の業務または電気通信設備に支障を及ぼし、または支障を及ぼすおそれのある行為が行われたとき。
              </li>
            </ol>
          </li>
          <li>
            当社は、前項の規定により本商品の利用を停止するときは、原則として利用者に対する特段の通知は行いません。ただし、ユーザー登録により利用者に対する通知方法が当社に判明する場合は、通知することがあります。
          </li>
          <li>
            本条に基づく利用の停止があっても、本商品の利用料金(月額基本料等および音声オプションサービス料)は発生します。当社は、本条に基づく利用の停止について、本商品の料金の全部または一部の返金または損害賠償は行いません。
          </li>
        </ol>

        <div className={classes.agreementMainPoint}>
          第34条(当社による利用契約の解除)
        </div>
        <ol>
          <li>
            当社は、第33条(利用の停止)第1項の規定により本商品の利用を停止された利用者が、なおその事実を解消しない場合には、その利用契約を解除することがあります。
          </li>
          <li>
            当社は、利用者が第33条(利用の停止)第1項各号の規定のいずれかに該当する場合で、その事実が当社の業務の遂行上著しい支障が認められるときは、前項の規定にかかわらず、利用停止をしないでその利用契約を解除することがあります。
          </li>
          <li>
            当社は、利用者が届け出た銀行口座等の会員資格が喪失された場合、その他の事由により銀行口座等から利用料金の決済を受けられないことが判明した場合、当社所定の基準により利用契約を解除することがあります。
          </li>
          <li>
            第33条(利用の停止)第2項および第4項の規定は、本条により当社が利用契約を解除する場合に準用します。
          </li>
        </ol>

        <h3 className={classes.agreementSectionHeading}>
          第六章　端末機器およびSIMカード
        </h3>
        <div className={classes.agreementMainPoint}>
          第35条(端末機器利用にかかる利用者の義務)
        </div>
        <ol>
          <li>
            利用者は、端末機器を電気通信事業法および電波法関係法令が定める技術基準(以下、「技術基準」といいます)に適合するよう維持するものとします。
          </li>
          <li>
            利用者は、端末機器について次の事項を遵守するものとします。
            <ol>
              <li>
                端末機器を取り外し、変更し、分解し、もしくは損壊しまたはその設備に線条その他の導体等を接続しないこと。ただし、天災事変その他の事態に際して端末機器を保護する必要があるときはこの限りではありません。
              </li>
              <li>
                故意に接続回線に保留したまま放置し、その他通信の伝送交換に妨害を与える行為を行わないこと。
              </li>
              <li>
                端末機器に登録されている電話番号その他の情報を読出し、変更または消去しないこと。
              </li>
            </ol>
          </li>
        </ol>

        <div className={classes.agreementMainPoint}>
          第36条(当社端末機器等の修理)
        </div>
        <p>
          利用者は、当社端末機器またはSIMカードの故障・破損等により当社端末機器またはSIMカードを通信に利用することができなくなったときは、原則として当社のヘルプデスクを経由して、当社に対しかかる当社端末機器またはSIMカードの修理を請求することができます。修理の方法および費用等については、当社が別途定める故障修理規定によるものとします
        </p>

        <div>第37条(自営端末機器)</div>
        <ol>
          <li>
            利用者は、SIM商品の利用にあたっては、技術基準に適合し、データ通信サービスまたは音声サービスに対応した自営端末機器を自ら用意するものとします。
          </li>
          <li>
            利用者は、SIM商品を利用している自営端末機器が技術基準に適合しない場合、当該自営端末機器での本商品の利用を中止するものとします。
          </li>
        </ol>

        <div className={classes.agreementMainPoint}>第38条(SIMカード)</div>
        <ol>
          <li>本商品に含まれるSIMカードは当社が利用者に貸与するものです。</li>
          <li>
            利用者は、SIMカードを善良な管理者の注意をもって管理するものとします。
          </li>
          <li>利用者は、SIMカードを改造してはならないものとします。</li>
          <li>
            利用者は、SIMカードに登録されている電話番号その他の情報を読出し、変更または消去してはならないものとします。
          </li>
          <li>
            利用者は、本商品の利用終了後、速やかにSIMカードを当社に返還するものとします。
          </li>
        </ol>

        <h3 className={classes.agreementSectionHeading}>第七章　通信の制限</h3>
        <div className={classes.agreementMainPoint}>第39条(通信区域)</div>
        <ol>
          <li>
            本商品による通信(データ通信サービスおよび音声サービスを総称するものとし、以下、「通信」といいます)の区域は、携帯電話事業者の通信区域の通りとします。本商品による通信は、接続されている端末機器が通信区域内に在圏する場合に限り行うことができます。ただし、当該通信区域内であっても、屋内、地下駐車場、ビルの陰、トンネル、山間部等電波の伝わりにくい場所では、通信を行うことができない場合があります。
          </li>
          <li>
            前項の場合、利用者は当社に対し、当社の故意または重大な過失により生じた場合を除き、本商品による通信を利用できないことによるいかなる損害賠償も請求することはできません。
          </li>
        </ol>

        <div>第40条(通信利用の制限)</div>
        <ol>
          <li>
            当社は、技術上、保守上、その他当社の事業上やむをえない事由が生じた場合、または、携帯電話事業者の提供する電気通信サービスの契約約款の規定もしくは携帯電話事業者と当社との間で締結される契約の規定に基づいて携帯電話事業者による通信利用の制限が生じた場合、本商品による通信を一時的に制限することがあります。
          </li>
          <li>
            前項の場合、利用者は当社に対し、当社の故意または重大な過失により生じた場合を除き、通信が制限されることによるいかなる損害賠償も請求することはできません。
          </li>
        </ol>

        <div>第41条(通信時間等の制限)</div>
        <ol>
          <li>
            第40条(通信利用の制限)の規定による場合のほか、当社は、通信が著しくふくそうするときは、通信時間または特定の地域の通信の利用を制限することがあります。
          </li>
          <li>
            前項の場合において、天災、事変その他の非常事態が発生し、または発生するおそれがある場合の災害の予防もしくは救援、交通、通信もしくは電力の供給の確保または秩序の維持のために必要な事項を内容とする通信および公共の利益のために緊急を要する事項を内容とする通信を優先的に取り扱うため、電気通信事業法施行規則の規定に基づき総務大臣が告示により指定した機関が使用している移動無線装置(当社または携帯電話事業者がそれらの機関との協議により定めたものに限ります)以外のものによる通信の利用を中止する措置(特定の地域の契約者回線等への通信を中止する措置を含みます)をとることがあります。
          </li>
          <li>
            当社は、一の通信について、その通信時間が一定時間を超えるとき、またはその通信容量が一定容量を超えるときは、その通信を切断することがあります。
          </li>
          <li>
            当社は、利用者間の利用の公平を確保し、本商品による通信を円滑に提供するため、動画再生やファイル交換(P2P)アプリケーション等、帯域を継続的かつ大量に占有する通信手順を用いて行われるデータ通信について速度や通信量を制限することがあります
          </li>
          <li>
            前四項の場合、利用者は当社に対し、通信時間等が制限されることによるいかなる損害賠償も請求することはできません。
          </li>
          <li>
            当社は、本条に規定する通信時間等の制限を実施するため、通信にかかる情報の収集、分析および蓄積を行うことがあります。
          </li>
        </ol>

        <div className={classes.agreementMainPoint}>第42条(通信時間の測定)</div>
        <ol>
          <li>
            通信時間は、双方の契約回線等を接続して通信できる状態にした時刻(その通信が手動接続通信であって通信の相手を指定したものであるときは、その指定した相手と通信することができる状態にした時刻とします。)から起算し、発信者または着信者による通信終了の信号を受けその通信をできない状態にした時刻までの経過時間とし、当社の機器(協定事業者の機器を含みます)により測定します。
          </li>
          <li>
            ただし、契約回線の故障等、通信の発信者または着信者の責めに帰すことのできない事由により通信を一時的に制限されたとき(第40条(通信利用の制限)により通信を一時的に制限された場合は、その制限を通知したときとします)は、協定事業者が別途定める規定による時間を通信時間とします。
          </li>
        </ol>

        <h3>第八章 保守</h3>
        <div>第43条(当社の維持責任)</div>
        <p>
          当社は、当社の電気通信設備を事業用電気通信設備規則(昭和60年郵政省令第30号)に適合するよう維持します。
        </p>
        <div>第44条(修理または復旧)</div>
        <ol>
          <li>
            当社は、当社の設置した電気通信設備が故障し、または滅失した場合はすみやかに修理し、または復旧するものとします。ただし、24時間以内の修理または復旧を保証するものではありません。
          </li>
          <li>
            当社は、当社の電気通信設備の設置、修理、復旧等にあたって、その電気通信設備に記憶されているメッセージ等の内容等が変化または消失したことにより利用者に損害を与えた場合、それが当社の故意または重大な過失により生じたものであるときを除き、その損害を賠償しません。
          </li>
        </ol>
        <div>第45条(保証の限界)</div>
        <ol>
          <li>
            当社は、本商品による通信の利用に関し、当社の電気通信設備を除き、相互接続点等を介し接続している、電気通信設備にかかる通信の品質を保証することはできません。
          </li>
          <li>
            当社は、インターネットおよびコンピュータに関する技術水準、通信回線等のインフラストラクチャーに関する技術水準およびネットワーク自体の高度な複雑さにより、現在の一般的技術水準をもっては本商品に瑕疵のないことを保証することはできません。
          </li>
        </ol>

        <h3 className={classes.agreementSectionHeading}>第九章　損害の賠償</h3>
        <div className={classes.agreementMainPoint}>
          第46条(当社の責めに帰すべき事由による損害)
        </div>
        <ol>
          <li>
            当社は、通信を提供すべき場合において、当社の責めに帰すべき事由により通信を全く利用できない状態(全ての通信に著しい支障が生じ、全く利用できない状態と同程度の状態となる場合を含みます。以下、同様とします)となり、そのことを当社が知った時刻から起算して、24時間以上その状態が継続したときに限り、その利用不能による損害を賠償します。
          </li>
          <li>
            前項の場合における賠償は、以下に定める方法により行います。ただし、当社は状況に応じて、これとは別の方法により賠償を行う場合があります。
            <p>基本サービスおよび音声オプションサービス</p>
            <p>
              月額基本料等および音声オプションサービス料から、当社が適当と認める金額を減じる方法
            </p>
          </li>
          <li>
            前項の場合において、減じる金額は、通信を全く利用できない状態が継続した時間(24時間の倍数である部分に限ります。以下、同様とします)を24時間ごとに数え上げて得た日数(以下、「利用不能日数」といいます)に相当する金額に限るものとします。
          </li>
          <li>
            前項にかかわらず、利用者が、通信の利用不能により通常生ずべき損害を賠償するためには当該利用不能日数に相当する金額を超える金額を減じるべきであることを証明した場合は、この限りではありません。
          </li>
          <li>
            前四項の規定は、当社に故意または重大な過失がある場合は適用されないものとします。
          </li>
        </ol>

        <div className={classes.agreementMainPoint}>
          第47条(協定事業者の責めに帰すべき事由による損害)
        </div>
        <ol>
          <li>
            当社は、通信を提供すべき場合において、協定事業者が当社に提供する接続サービスの障害等、協定事業者の責めに帰すべき事由により通信を提供できなかった場合であって協定事業者から当社に対し損害が賠償された場合に限り、当該賠償額を、通信を利用できなかった利用者全員に対する損害賠償の総額とし、減じるべき金額に換算したうえで、その利用不能による損害を賠償します。
          </li>
          <li>
            前項の場合における賠償の方法は、第46条(当社の責めに帰すべき事由による損害)第2項の規定が準用されるものとします。
          </li>
        </ol>

        <div className={classes.agreementMainPoint}>第48条(不可抗力免責)</div>
        <p>
          天災、事変、原因不明のネットワーク障害その他の不可抗力により、利用者が通信を利用できなかったときは、当社は、一切その責任を負わないものとします。
        </p>

        <div>第49条(本商品の利用または利用不能から派生した損害)</div>
        <ol>
          <li>
            当社は、利用者が本商品を利用することにより得た情報等(コンピュータプログラムを含みます)について、その品質、完全性、正確性、特定目的への適合性、有用性、ウイルスの不存在その他何らの保証も行いません。当該情報等のうち当社以外の第三者による提供にかかるものに起因して生じた損害について、当社は何らの責任も負わないものとします。
          </li>
          <li>
            当社は、本商品を利用した場合に生じた、情報等の破損、滅失もしくは第三者に対する漏洩による損害について、当社の故意または重大な過失により生じた場合を除き、一切の責任を負わないものとします。
          </li>
          <li>
            当社は、本商品の不具合その他の瑕疵、利用者による本商品の利用もしくは利用不能、または利用者に対するサポートサービスの提供もしくは提供不能の結果として生ずべき利用者の逸失利益、機密情報の損失、事業の中断、人身傷害、プライバシーの侵害、その他利用者が被るべき一切の金銭的および非金銭的損害、損失ならびに費用に関し、当社の故意または重大な過失により生じた場合を除き、一切の責任を負わないものとします。
          </li>
        </ol>

        <div className={classes.agreementMainPoint}>
          第50条(損害賠償額の上限)
        </div>
        <p>
          当社が利用者に対して損害賠償責任を負う場合の全てについて、その損害賠償の範囲は、当該利用者に現実に発生した通常損害の範囲に限られるものとし、かつ、その総額は当社が当該損害の発生までに当該利用者から受領した料金の額を上限とします。ただし、当社に故意もしくは重大な過失がある場合、または第47条(協定事業者の責めに帰すべき事由による損害)に規定する場合はこの限りではありません。
        </p>

        <h3>第十章　サポート</h3>
        <div>第51条(サポート)</div>
        <ol>
          <li>
            当社は、利用者に対し、本商品の利用期間中、本商品の利用に関する技術サポートを提供します。
          </li>
          <li>
            当社は、前項に定めるものを除き、利用者に対し、保守、デバッグ、アップデートまたはアップグレード等のいずれを問わず、いかなる技術的役務も提供する義務を負いません。
          </li>
          <li>
            当社は、利用者に提供している本商品のアップデート等のサービスを中止する権利を留保します。
          </li>
          <li>
            当社は、本商品の利用に関する一般的な技術情報を除く、いかなる技術情報も提供する義務を負いません。
          </li>
        </ol>

        <div className={classes.agreementMainPoint}>第52条(情報の収集)</div>
        <ol>
          <li>
            当社は、本商品に関し、利用者に技術サポート等を提供するために必要な情報を収集、利用することがあります。利用者から必要な情報が提供されない場合、十分な技術サポート等を提供できないことがあります。
          </li>
          <li>
            当社は、前項により当社が利用者から収集した情報について、技術サポートのほか、当社サービスの提供に伴う利用者の本人確認、アフターサービス、新商品およびキャンペーン情報等のご案内ならびに商品開発およびサービス向上等のための調査に利用することがあります。ただし、利用者を特定できる形で公開することはありません。
          </li>
        </ol>

        <h3 className={classes.agreementSectionHeading}>第十一章　雑則</h3>
        <div className={classes.agreementMainPoint}>
          第53条(携帯電話事業者との契約)
        </div>
        <p>
          利用者は、本商品を利用するにあたり利用者と携帯電話事業者との間で接続契約が締結され、本商品の利用の終了により接続契約が解約される場合があることを了承します。その場合、当社が当該接続契約の申込および解約を携帯電話事業者に取り次ぐものとします。なお、利用者において特段の手続きは不要です。
        </p>
        <div>第54条(商品の販売終了)</div>
        <ol>
          <li>
            当社は、本商品の全部または一部の販売を予告なく終了することがあります。
          </li>
          <li>
            当社は、前項の規定により本商品の販売を終了したときは、当社Webサイトにおいて利用者に告知します。
          </li>
          <li>
            本条第1項の規定により当社が本商品の販売を終了した場合であっても、当該時点で本商品の利用を開始している利用者は、引き続き本商品を利用することができます。ただし、第56条(商品及びキャンペーンの廃止)の規定により当社が本商品を廃止した場合は、この限りではありません。
          </li>
          <li>
            本条第1項の規定により当社が本商品の販売を終了した場合であっても、当該時点で利用を開始していない本商品を所有している利用者は、第6条(申込み)第1項に定める申込み(以下、「利用開始手続き」といいます)により、本商品の利用を開始することができます。ただし、第55条(利用開始手続きの受付終了)の規定により当社が本商品の利用開始手続きの受付を終了した場合または第56条(商品及びキャンペーンの廃止)の規定により当社が本商品を廃止した場合は、この限りではありません。
          </li>
        </ol>

        <div className={classes.agreementMainPoint}>
          第55条(利用開始手続きの受付終了)
        </div>
        <ol>
          <li>
            当社は、第54条(商品の販売終了)の規定により販売を終了した商品について、利用開始手続きの受付を終了することがあります。
          </li>
          <li>
            当社は、前項の規定により本商品の利用開始手続きの受付を終了するときは、本商品の利用開始手続きの受付を終了する日までに相当な期間をおいて、第5条(当社からの告知)の方法により利用者に告知します。
          </li>
          <li>
            本条第1項の規定により当社が本商品の利用開始手続きの受付を終了した場合であっても、本商品の利用者は継続して本商品を利用することができます。ただし、第56条(商品及びキャンペーンの廃止)の規定により当社が本商品を廃止した場合は、この限りではありません。
          </li>
        </ol>

        <div className={classes.agreementMainPoint}>
          第56条(商品及びキャンペーンの廃止)
        </div>
        <ol>
          <li>
            当社は、本商品の全部または一部及び、キャンペーン等を廃止することがあります。
          </li>
          <li>
            当社は、前項の規定により本商品を廃止するときは、本商品を廃止する日までに相当な期間をおいて、第5条（当社からの告知）の方法により利用者に告知します。
            <p>
              新プランのずっーと割引最大２５％ＯＦＦキャンペーンについては、２０２３年１２月１日より、新規契約において累計１万回線の契約となった場合は、終了するものとする。　尚、既存の回線については、キャンペーン終了後も割引価格が適応されるものとする。
            </p>
          </li>
        </ol>

        <div className={classes.agreementMainPoint}>第57条(分離性)</div>
        <p>
          本利用規定の一部分が無効で強制力を持たないと判明した場合でも、本利用規定の残りの部分の有効性はその影響を受けず引続き有効で、その条件に従って強制力を持ち続けるものとします。
        </p>

        <div className={classes.agreementMainPoint}>
          第58条(反社会的勢力に対する表明保証)
        </div>
        <ol>
          <li>
            契約者は、サービス利用契約締結時および締結後において、自らが暴力団または暴力団関係企業・団体その他反社会的勢力(以下、総称して「反社会的勢力」という)ではないこと、反社会的勢力の支配・影響を受けてないことを表明し、保証するものとします。
          </li>
          <li>
            契約者が次の各号のいずれかに該当することが合理的に認められた場合、当社はなんら催告することなくサービス利用契約を解除することができるものとします。
            <ol>
              <li>反社会的勢力に属していること</li>
              <li>反社会的勢力が経営に実質的に関与していること</li>
              <li>反社会的勢力を利用していること</li>
              <li>
                反社会勢力に対して資金等を提供し又は便宜を供与するなどの関与をしていること
              </li>
              <li>反社会的勢力と社会的に避難されるべき関係を有していること</li>
              <li>
                自らまたは第三者を利用して関係者に対し、詐術、暴力的行為、または脅迫的言辞を用いたこと
              </li>
            </ol>
          </li>
          <li>
            前項各号のいずれかに該当した契約者は、当社が当該解除により被った損害を賠償する責任を負うものとし、自らに生じた損害の賠償を当社に求めることはできないものとします。
          </li>
        </ol>
        <div className={classes.agreementMainPoint}>第59条(準拠法)</div>
        <p>本利用規定は、日本国法を準拠法とします。</p>

        <div className={classes.agreementMainPoint}>第60条(協議)</div>
        <p>
          当社および利用者は、本商品または本利用規定に関して疑義が生じた場合には、両者が誠意をもって協議のうえ解決するものとします。
        </p>

        <div className={classes.agreementMainPoint}>第61条(管轄裁判所)</div>
        <p>
          当社および利用者は、本商品または本利用規定に関して紛争が生じた場合は、大阪地方裁判所を第一審の専属管轄裁判所とすることに合意します。
        </p>

        <div className={classes.agreementMainPoint}>付則(実施時期)</div>
        <p>本利用規定(第5版)は、令和5年12月1日から実施します。</p>
        <p>
          <strong>改訂履歴</strong>
        </p>
        <p>初版令和4年2月1日</p>
        <p>第2版令和5年2月21日</p>
        <p>第3版令和5年3月8日</p>
        <p>第4版令和5年8月1日</p>
        <div ref={lastElement}>
          <p>第5版令和5年12月1日</p>
        </div>
      </div>
    </div>
  );
}
export default ExtraInfo;
