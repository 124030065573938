import classes from "./SuspensionAndCancellation.module.css";

function SuspensionAndCancellation() {
  return (
    <div>
      <h2 className={classes.agreementSectionHeading}>
        第五章 利用の中断、一時中断、利用の停止および解除
      </h2>
      <div>
        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>第 31 条（利用の中断）</h3>
        </div>
      </div>
    </div>
  );
}
export default SuspensionAndCancellation;
