import { useSelector } from "react-redux";
import FileUpload from "../DocumentSubmission/FileUpload";
import ProgressButton from "../commonComponents/ProgressButton";
import classes from "./InitialFeeReceiptPage.module.css";

function InitialFeeReceiptPage(props) {
  const initialFeeReceipt = useSelector(
    (state) => state.order.initialFeeReceipt
  );
  const handleProceedToNextStage = () => {
    props.onComplete(5);
  };
  return (
    <div className={classes.InitialFeeReceiptPage}>
      <div className={classes.fileUploadContainer}>
        <FileUpload
          elementId={"initialFeeDoc"}
          cardText={"初期費用受領確認書"}
          type={"document"}
          onUploaded={props.onUploaded}
          submittedFile={initialFeeReceipt}
        />
      </div>
      <div className={classes.buttonContainer}>
        <ProgressButton
          buttonText="プラン内容・入力確認に進む"
          isDisabled={initialFeeReceipt ? false : true}
          onStepCompleted={handleProceedToNextStage}
        />
      </div>
    </div>
  );
}
export default InitialFeeReceiptPage;
