import { useEffect, useRef, useState } from "react";

import { useDispatch } from "react-redux";
import Ripples from "react-ripples";
import { orderActions } from "../../store/orderSlice";
import Compressor from "compressorjs";
import FileUploadButton from "./FileUploadButton";
import ReUploadButton from "./ReUploadButton";
import classes from "./fileUpload.module.css";

// import fileCompress from "./fileCompress";

function FileUpload(props) {
  const [image, setImage] = useState("");

  const viewBox = useRef();
  const [src, setSrc] = useState(null);
  const [fileIsViewable, setFileIsViewable] = useState(true);
  const dispatch = useDispatch();

  const documentLogo = "/images/document.svg";
  const cardLogo = "/images/idcard.svg";
  const bookLogo = "/images/book.svg";

  const handleChange = (event) => {
    const [file] = event.target.files;
    //convert to Base 64
    const reader = new FileReader();

    if (file) {
      new Compressor(file, {
        quality: 0.5,
        success(result) {
          //set it as the image
          reader.onloadend = () => {
            setImage(reader.result.toString());
          };
          reader.readAsDataURL(result);
          const fileUrl = URL.createObjectURL(result);
          setSrc(fileUrl);
          dispatch(
            orderActions.setIdImageAndDocs({
              id: props.elementId,
              value: fileUrl,
            })
          );
        },
      });
    }
  };
  useEffect(() => {
    if (image) {
      props.onUploaded(props.elementId, image);
    } else {
      return;
    }
  }, [image]);

  const acceptUploadedFile = () => {
    dispatch(
      orderActions.setIdImageAndDocs({ id: props.elementId, value: src })
    );
    setFileIsViewable(false);
  };
  const handleFileUpload = (event) => {
    if (src) {
      event.preventDefault();
    } else {
      return;
    }
  };
  const handleUploadView = () => {
    setFileIsViewable((prevVal) => !prevVal);
  };

  useEffect(() => {
    if (props.submittedFile) {
      setSrc(props.submittedFile);
      setFileIsViewable(false);
    } else {
      setSrc(null);
    }
  }, []);

  return (
    <div className={classes.fileUploadContainer}>
      <div className={classes.fileUploadSection}>
        <Ripples className={classes.rippleDiv}>
          <div className={classes.labelContainer}>
            <label
              htmlFor={props.elementId}
              className={classes.fileUploadLabel}
              onClick={handleFileUpload}
            >
              <span className={classes.labelDescContainer}>
                {props.type === "id" && (
                  <img
                    src={
                      props.cardText.includes("しょうがい者手帳")
                        ? bookLogo
                        : cardLogo
                    }
                    alt="Id upload"
                  />
                )}
                {props.type === "document" && (
                  <img src={documentLogo} alt="Id upload" />
                )}
                {props.cardText}
              </span>
              <img
                src={src ? "/images/arrowpointer.svg" : "/images/camera.svg"}
                alt="camera-icon"
                className={
                  fileIsViewable && src
                    ? classes.arrowNormal
                    : classes.arrowOpen
                }
                onClick={handleUploadView}
              />
            </label>
          </div>
        </Ripples>
        <input
          type="file"
          name={props.elementId}
          id={props.elementId}
          className={classes.formControl}
          onChange={handleChange}
          accept="image/*"
        />
        {src && fileIsViewable && (
          <div className={classes.viewBoxContainer}>
            <img
              src={src}
              alt="view of uploaded document"
              ref={viewBox}
              className={classes.viewImage}
            />
          </div>
        )}
        {src && fileIsViewable && (
          <div className={classes.controlButtonContainer}>
            <ReUploadButton elementId={props.elementId} />

            <FileUploadButton
              buttonText="決定"
              isDisabled={false}
              doNextAction={acceptUploadedFile}
              acceptSubmission
            />
          </div>
        )}
      </div>
    </div>
  );
}
export default FileUpload;
