import classes from "./information.module.css";

function Information() {
  return (
    <div>
      <h2 className={classes.InformationHeading}>第三章 料 金</h2>
      <p className={classes.InformationHeadingParagraph}>
        {" "}
        第1節 基本サービス{" "}
      </p>
      <div>
        {/* <p className={classes.agreementMainPointIntroText}> </p> */}
        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>
            第 11 条（料金の支払義務）
          </h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                本商品には、予めパッケージを購入して利用契約を申し込む場合があります。この場合、パッケージの代金は、申込みの前後または利用開始の前後を問わず、利用者の都合により本商品を利用できない場合または利用しない場合であっても、返金は行いません。
              </li>
              <li>
                本商品の利用者は、利用契約の開始日（当社所定の手続きを経て本商品の利用が可能になった日をいいます）から利用契約の終了日が属する料金月の末日までの期間について、本商品の利用料金を支払うものとします。
              </li>
              <li>
                本商品の利用料金は、初期手数料、月額基本料等、通話料等、および各種手続きにかかる手数料とします。料金額および課金日等は当社
                Web サイトにおいて定めるとおりとします。
              </li>
              <li>
                当社は、本商品の利用料金のうち通話料等に上限を設けることがあります。この場合、本商品の利用者は、本条第
                2 項および第 3
                項にかかわらず、通話料等が上限に達した時点で当該通話料等を支払うものとします。
              </li>
              <li>
                本商品の月額基本料等については料金月で課金され、利用開始月は日割り計算とし、利用終了月は日割計算を行いません。但し契約時は利用月の翌月の基本料金並びにオプション料金と事務手事務及びその他諸費用を支払うものとする。
              </li>
              <li>
                本条第2 項にかかわらず、第8条（利用者による解約）第3
                項なお書に定める終了日の属する暦月または料金月の月額基本料等は発生しないものとします。ただし、通話料等についてはこの限りではありません。
              </li>
              <li>
                前六項で定める料金が消費税の課税対象である場合は、料金表に基づき、当該料金に消費税相当額を加算した額をお支払いいただきます。
              </li>
            </ol>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>
            第 12 条（音声通話サービスの通話料））
          </h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                音声通話サービスの通話料は、１回の通話において、当社が定める一定の通話時間（以下、基準通話時間」といいます）ごとに計算するものとします。ただし、基準通話時間に満たない通話時間は切り上げるものとします
              </li>
              <li>
                当社は、本商品において、前項により計算される通話料の一部を月額基本料に含むものとし音声通話サービスの通話料としては請求しない（以下、「無料通話」といいます）
                仕様とすることがあります。ただし、以下の各号に該当する音声通話サービスは、無料通話の対象外とします
                <ol>
                  <li>国内通話以外の通話（国際ローミング、国際電話）</li>
                  <li>
                    国内通話のうち以下の電話番号に発信する通話
                    <ol>
                      <li>衛星電話および衛星船舶電話</li>
                      <li>
                        0570（ナビダイヤル）、0180（テレドーム）などの他社サービス
                      </li>
                      <li>104（電話番号案内料）、電報等</li>
                      <li>
                        一方的または機械的な発信により、長時間または多数の通信を一定期間継続して接続する電話番号として当社が指定する電話番号
                      </li>
                      <li>
                        他社着信転送サービス（他の電気通信事業者が有する電話番号を介して他の電話番号に着信させることを主な目的とするサービス）に該当するものと当社が判断する電話番号
                      </li>
                    </ol>
                  </li>
                  <li>
                    一般的な利用と著しく異なる利用態様が認められるなどにより、通話以外の目的によるものと当社が判断する場合
                  </li>
                  <li>
                    かけ放題について（当社が提供する「かけ放題サービス」についての範囲）
                    <ul>
                      <li>
                        当社及び当社と上位キャリア及びＮＴＴドコモにおいて契約上、著しい発信通話があると認められ、上位キャリアより当社へ通話時間において検知された場合は、当社及び上位キャリアより、本契約のサービス停止または解約となる場合があります。
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>
            第 13 条（初期契約解除に伴い利用者が支払う料金）
          </h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                第11 条（料金の支払義務）の規定にかかわらず、第 7
                条（初期契約解除）に定める初期契約解除が行われた場合に利用者が支払う料金は、以下の各号に定める料金の合計額とします。
                <ol>
                  <li>
                    月額基本料等、通話料等およびオプションサービスにかかる料金のうち、初期契約解除による利用契約の終了日までに当社が提供したサービスの対価に相当する金額
                  </li>
                  <li>
                    初期手数料または利用契約の変更手続きにかかる手数料及び送料等
                  </li>
                </ol>
              </li>
              <li>
                当社は、初期契約解除が行われた場合に、利用者から前項に定める料金を超える金額を受領していた場合は、当社の定める時期および方法により、利用者に超過分を返還します。なお、返還に要する費用は当社が負担するものとします。
              </li>
              <li>
                購入された端末【スマートフォン本体及びポケットWI-FI等】は初期契約解除に該当されず、初期不良以外での返金または交換は出来ません。【総務省ガイドラインより】
              </li>
            </ol>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>
            第14条（料金の支払方法等）
          </h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                本商品の利用料金は、当社が別途定める場合を除き、原則当社規定の口座振替にて支払うものとします。但し、当社の判断により振込して支払うことも可能とします。その際は振込手数料は、振込者の負担とします。
              </li>
              <li>
                本商品の利用料金の支払は、毎月当社の指定する支払期日にて原則口座振替にて支払うものとする。
              </li>
              <li>
                当社は、毎月の課金日その他必要な時点において、当社所定の基準により必要な料金を請求する場合はあります。
              </li>
              <li>
                振替口座の登録を変更される場合は、変更手数料として５５０円及び送金に係る費用を必要とする
              </li>
              <li>
                甲がナンバーポータビリティ(MNP予約番号の発行)を乙へ依頼する場合、総務省のガイドラインに基づき、コールセンターへの電話連絡または対面での依頼の際は、事務手数料として1,100円を
                支払うことを了承する。但し、メールでの（help@sky7mobile.ne.jp）依頼の場合は、事務手数料は発生しない。
              </li>
            </ol>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>第 15 条（割増金）</h3>
          <div className={classes.agreementDetail}>
            <p>
              本商品の利用者が料金の支払を不法に免れた場合は、その免れた額のほか、その免れた額（消費税相当額を加算しない額とします。以下、同様とします）の
              2
              倍に相当する額に消費税相当額を加算した額（料金表において消費税を加算しないこととされている料金にあってはその免れた額の
              2 倍に相当する額）を割増金として支払うものとします。
            </p>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>第 16 条（延滞利息）</h3>
          <div className={classes.agreementDetail}>
            <p>
              本商品の利用者は、料金その他の債務（延滞利息を除きます。以下、同様とします）について支払期日を経過してなお支払いがない場合には、当該料金その他の債務の他、支払期日の翌日から支払いの日の前日までの日数について、年
              14.5％の割合で計算して得た額を延滞利息として当社が別途定める方法により支払うものとします。
              前項の規定にかかわらず、初期契約解除をした利用者は、第 13
              条（初期契約解除に伴い利用者が支払う料金）第 1
              項に定める料金について支払期日を経過してなお支払いがない場合には、当該料金の他、支払期日の翌日から支払いの日の前日までの日数について、年３％の割合で計算して得た額を延滞利息として当社が別途定める方法により支払うものとします。
            </p>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <p className={classes.agreementMainPointIntroText}>
            第2節 オプションサービス
          </p>
          <h3 className={classes.agreementMainPoint}>
            第 18 条（音声オプションサービスの料金）
          </h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                音声オプションサービスを利用する場合は、音声オプションサービス料（有料サービスを利用する場合に限るものとします。以下、同様とします）を支払うものとします。
              </li>
              <li>
                音声オプションサービス料の料金は、暦月で課金し、商品の仕様として別に定める場合を除き、利用開始月は日割り計算、利用終了月は日割り計算とはなりません。
              </li>
              <li>
                音声オプションサービスのうち一部のサービスの利用にあたっては、別途通話料等が発生します。
              </li>
              <li>第11 条第7 項の規定は、前三項で定める料金に準用します。</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Information;
