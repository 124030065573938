import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AgreementPage from "../../Agreement/AgreementPage";
import { orderActions } from "../../store/orderSlice";
import ProgressButton from "../commonComponents/ProgressButton";
import DataSMSPlans from "./DataSMSPlans";
import MNPSection from "./MNPSection";
import OrderPlan from "./OrderPlan";
import OrderPlanCheckList from "./OrderPlanChecklist";
import PlanOptions from "./PlanOptions";
import PocketWifiPlans from "./PocketWifiPlans";
import VoiceUnlimitedCallPlans from "./VoiceUnlimitedCallPlans";
import classes from "./orderPlanSelection.module.css";
import { AnimatePresence, motion } from "framer-motion";

function OrderPlanSelection({ onComplete }) {
  const termsAreAgreed = useSelector((state) => state.order.termsRead);
  const [MNPInputValid, setMNPInputsValid] = useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(true);

  const dispatch = useDispatch();

  const selectedPlan = useSelector((state) => state.order.contractPlan);
  const activeButton = useSelector((state) => state.order.contractPlan);

  // const deliveryMethod = useSelector((state) => state.order.deliveryMethod);
  const deliveryType = useSelector((state) => state.order.mainApplicationType);

  const setApplicationProcessStage = () => {
    onComplete(2);
  };

  const submitBundlePlan = (contractPlan) => {
    if (contractPlan === "音声プラン") {
      dispatch(orderActions.setOrderPlan(contractPlan));
      dispatch(
        orderActions.setSelectedBundle({ amountPlusTax: 3256, bundleSize: 1 })
      );
    } else if (contractPlan === "データ＋ＳＭＳプラン") {
      dispatch(orderActions.setOrderPlan(contractPlan));
      dispatch(
        orderActions.setSelectedBundle({ amountPlusTax: 1650, bundleSize: 3 })
      );
      dispatch(orderActions.setSelectedPlanOptions("none"));
    } else {
      switch (contractPlan) {
        case "ポケットWifi":
          dispatch(orderActions.setOrderPlan(contractPlan));
          dispatch(
            orderActions.setSelectedBundle({
              amountPlusTax: 20600,
              bundleSize: 100,
            })
          );
          break;
        case "ポケットWifi2":
          dispatch(orderActions.setOrderPlan(contractPlan));
          dispatch(
            orderActions.setSelectedBundle({
              amountPlusTax: 23350,
              bundleSize: 300,
            })
          );
          break;
        default:
          break;
      }
    }
  };
  const setMNPState = (mnpState) => {
    setMNPInputsValid(mnpState);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  useEffect(() => {
    if (MNPInputValid && termsAreAgreed) {
      setButtonIsDisabled(false);
    } else {
      setButtonIsDisabled(true);
    }
  }, [MNPInputValid, termsAreAgreed]);

  const showCheckList =
    deliveryType === "新プラン" || deliveryType === "他社からの乗り換え(MNP)";
  const isWifiContract = deliveryType === "wifi";

  return (
    <AnimatePresence>
      <motion.div
        className={classes.OrderPlanSelectionContainer}
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 20 }}
        layout
      >
        <h2 className={classes.orderPlanHeading}>
          プランやパケット容量とオプションを選択してください
        </h2>
        <div className={classes.orderPlanCardsContainer}>
          <p
            className={`${classes.planOptionsDescription} ${classes.required}`}
          >
            プラン選択
          </p>
          <div className={classes.orderPlanContainer}>
            {!isWifiContract && (
              <OrderPlan
                images={["phone.svg", "sms.svg", "wifi.svg"]}
                mainPlanText={"音声プラン通話(通話+インターネット)"}
                planStartingPrice={"3,256円(税込)/月～"}
                planDetails={"かけ放題(10分/回)"}
                value={"音声プラン"}
                onPlanSelected={submitBundlePlan}
                activeContract={activeButton}
                isDisabled={false}
              />
            )}
            {!showCheckList && !isWifiContract && (
              <OrderPlan
                images={["phone.svg", "sms.svg"]}
                mainPlanText={"データSMS付プラン"}
                planStartingPrice={"1,650円(税込)/月～"}
                planDetails={"(音声通話はできません)"}
                value={"データ＋ＳＭＳプラン"}
                onPlanSelected={submitBundlePlan}
                activeContract={activeButton}
                isDisabled={deliveryType === "新プラン"}
              />
            )}
            {isWifiContract && (
              <OrderPlan
                images={["wifi.svg"]}
                mainPlanText={"スカイセブンのWI-FI"}
                planStartingPrice={"6,600円(税込)/月"}
                planDetails={"100GB/月"}
                value={"ポケットWifi"}
                onPlanSelected={submitBundlePlan}
                activeContract={activeButton}
                isDisabled={deliveryType === "新プラン"}
              />
            )}
            {isWifiContract && (
              <OrderPlan
                images={["wifi.svg"]}
                mainPlanText={"スカイセブンのWI-FI"}
                planStartingPrice={"9,350円(税込)/月"}
                newPlanDetails={" ※1日あたり10GB/日まで高速通信となります。"}
                planDetails={"300GB/月"}
                value={"ポケットWifi2"}
                onPlanSelected={submitBundlePlan}
                activeContract={activeButton}
                isDisabled={deliveryType === "新プラン"}
              />
            )}
          </div>
        </div>

        <div className={classes.explanationTextContainer}>
          <p>プラン内におけるパケット容量の変更は可能です。</p>
        </div>
        {/* notices are displayed here */}
        {(selectedPlan === "データ＋ＳＭＳプラン" ||
          selectedPlan === "ポケットWifi" ||
          selectedPlan === "ポケットWifi2") && (
          <div className={classes.attentionContainer}>
            <p>ご注意</p>
            <p>
              こちらのプランでは「電話」がご利用いただけません。
              {selectedPlan === "データ＋ＳＭＳプラン"
                ? "(LINE通話などはご利用いただけます)"
                : " (LINEなどSMS認証が必要なサービスも使えません)"}
            </p>
            <p>
              090や080・070などの電話番号で電話をされる方
              {selectedPlan === "ポケットWifi" ||
              selectedPlan === "ポケットWifi2"
                ? "は,「音声プラン」を選択してください。"
                : "SMSをご利用の方は 他のプランを選択してください。"}
            </p>
          </div>
        )}
        {/* pocket wifi separation */}
        {isWifiContract && (
          <p
            className={`${classes.packetSelectionHeading} ${classes.required}`}
          >
            本体代金
          </p>
        )}

        {isWifiContract && <PocketWifiPlans unitPrice={true} />}

        {/* wifi separation ended */}
        <p className={`${classes.packetSelectionHeading} ${classes.required}`}>
          パケットプランを選択してください
        </p>

        {selectedPlan === "音声プラン" && !isWifiContract && (
          <VoiceUnlimitedCallPlans />
        )}

        {selectedPlan === "データ＋ＳＭＳプラン" && !isWifiContract && (
          <DataSMSPlans />
        )}

        {isWifiContract && (
          <PocketWifiPlans unitPrice={false} planList={true} />
        )}

        <div className={classes.explanationTextContainer}>
          <p>
            パケットプランの変更は、ご契約月の翌々月以降より毎月変更可能です。前月の25日まで受付となります。
          </p>
          <p>パケットの繰り越しは本プランはございません。</p>
          <p>データを使い切った後は、低速(128kbps)となります。</p>
          <p>
            ※300GBプランの場合は、1日あたり10GB/日を超えると、低速(128kbps)となります。
          </p>
        </div>

        {(deliveryType === "他社からの乗り換え(MNP)" ||
          deliveryType === "新プラン") && <MNPSection />}

        {selectedPlan === "音声プラン" && <PlanOptions />}

        <h2
          className={`${classes.termsAndConditionsheading} ${classes.required}`}
        >
          利用規約への同意
        </h2>

        <div>
          <AgreementPage />
        </div>
        <OrderPlanCheckList setMNPValidInputs={setMNPState} />

        <div className={classes.proceedButtonContainer}>
          <ProgressButton
            buttonText={"利用規約に同意してお客様情報入力に進む"}
            isDisabled={buttonIsDisabled}
            onStepCompleted={setApplicationProcessStage}
          />
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
export default OrderPlanSelection;
