const calculateCurrentSelectionCost = (
  contractPlan,
  selectedPlanPrice,
  catchPhone,
  answeringMachine,
  packetSize
) => {
  let daysLeftInMonth;

  let administrationFee = 3300;
  if (contractPlan === "音声プラン" && parseInt(packetSize) >= 10) {
    administrationFee = 0;
  } else if (
    contractPlan === "ポケットWifi2" ||
    contractPlan === "ポケットWifi"
  ) {
    administrationFee = 0;
  } else {
    administrationFee = 3300;
  }

  let catchPhonePrice = catchPhone ? 440 : 0;
  let answeringMachinePrice = answeringMachine ? 550 : 0;
  let selectedPlanPriceConv = parseInt(selectedPlanPrice);

  const today = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  daysLeftInMonth = lastDayOfMonth.getDate() - today.getDate();

  const costForRemainingDaysInMonth = 110 * daysLeftInMonth;
  const totalFee =
    selectedPlanPriceConv +
    administrationFee +
    catchPhonePrice +
    answeringMachinePrice;

  let currentPrice =
    selectedPlanPriceConv + catchPhonePrice + answeringMachinePrice;
  let totalFeeTo =
    selectedPlanPriceConv +
    administrationFee +
    catchPhonePrice +
    answeringMachinePrice +
    880;

  return {
    selectedPlan: contractPlan,
    totalFee: totalFee,
    onlyPlanFee: selectedPlanPriceConv,
    soleMonthlyFee: currentPrice,
    totalFeeTo: totalFeeTo,
    remainingMonthCost: costForRemainingDaysInMonth,
    administrationFee: administrationFee,
  };
};
export default calculateCurrentSelectionCost;
