export default function setImageAndDocs(state, action) {
  let payload = action.payload.value;
  let submitee = action.payload.id;

  if (submitee === "id_upload_1") {
    state.idFront = payload;
  } else if (submitee === "id_upload_2") {
    state.idBack = payload;
  } else if (submitee === "support_doc_1") {
    state.document_1 = payload;
  } else if (submitee === "support_doc_2") {
    state.document_2 = payload;
  } else if (submitee === "support_doc_3") {
    state.document_3 = payload;
  } else {
    state.initialFeeReceipt = payload;
  }
}
