import classes from "./MethodsOfUtilization.module.css";

function MethodsOfUtilization() {
  return (
    <div>
      <h2 className={classes.mouHeading}>第四章 利用方法</h2>
      <div>
        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>
            第 19 条（利用者情報の取得）
          </h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                当社は、利用者から、以下の各号に掲げる情報（以下、総称して「利用者情報」といいます）を取得するものとします
                <ol>
                  <li>
                    利用者が商品またはサービスの利用契約を申込むにあたり、第
                    6条（申込み）に基づいて当社に提供する情報：契約者情報（氏名、住所、生年月日、電話番号、メールアドレス、銀行等口座情報、本人確認書類に関する情報等）および契
                    約者以外に利用者が存在する場合の当該利用者に関する情報（18
                    歳未満である場合はその旨）
                  </li>
                  <li>
                    その他、当社が商品またはサービスの提供に付随して取得する情報：その他情報（請求明細、残存している利用可能通信量・最大利用期間、利用終了予定、通信使用量等）
                  </li>
                </ol>
              </li>
              <li>
                当社は、初期契約解除、ご契約内容の変更または解約の申込みについて、利用者情報の提供を条件とする場合があります。また、利用者が利用者情報の全部または一部を提供しない場合、当社のサポートサービスを提供できない場合があります。
              </li>
              <li>
                本節の規定は、本条第 1
                項の利用者情報の取扱いを含む利用方法全般について適用されます。
              </li>
            </ol>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>
            第 20 条（利用者アカウントの発行）
          </h3>
          <div className={classes.agreementDetail}>
            <p>
              {" "}
              当社は、利用者情報を提供した利用者に対し、利用者アカウントを発行し、当社所定の方法により、ご利用中のサービスに関する情報を提供します
            </p>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>
            第 21 条（利用者アカウントの管理）
          </h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                利用者は、利用者アカウントの
                ID、パスワード、その他利用者アカウントの認証のための情報（以下、「アカウント情報」といいます）を自己の責任において管理するものとします。利用者が法人または団体である場合、本商品
                1 個に対するアカウント情報は1
                つとし、法人または団体の管理担当者が管理するものとします。
              </li>
              <li>
                利用者は、アカウント情報を他者に使用させ、他者と共有し、または売買、譲渡もしくは貸与等をしてはならないものとします。
              </li>
              <li>
                アカウント情報の管理および使用は利用者の責任とします。アカウント情報の使用上の過誤または他者による無断使用により利用者が被る損害については、当該利用者の故意または過失の有無を問わず、当社は一切責任を負いません
              </li>
              <li>
                利用者のアカウント情報をもって本商品が利用されたときには、その利用者自身の利用とみなされるものとします。
              </li>
              <li>
                利用者のアカウント情報を使用し、利用者と他者により同時に、または他者のみによって使用された場合、本商品の通常の機能が失われることがあります
              </li>
            </ol>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>
            第 22 条（氏名等の変更の届出）
          </h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                利用者は、当社に提供した利用者情報に変更が生じた場合には、当社所定の方法により、当社に届け出るものとします。
              </li>
              <li>
                利用者情報に変更があったにもかかわらず、前項の届出がないときは、当社から利用者に対する通知は、当社に届け出られている利用者情報に基づいて行われ、当該通知をもってその通知を行ったものとみなします。また、当該通知は、通常到達すべき時期に到達したものとみなされるものとします。
              </li>
            </ol>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>
            第 23 条（サポートサービス）
          </h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                当社は、利用者に対し、本商品の利用期間中、本商品に関するサポートサービスを提供します。
              </li>
              <li>
                当社が利用者に対しサポートサービスの提供を行う場合、当社Web
                サイトにおいて告知し、または利用者に対し通知するものとします
              </li>
            </ol>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>
            第 24 条（自己責任の原則）
          </h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                利用者は、本商品を利用して行った、自己の行為およびその結果について、責任を負います。
              </li>
              <li>
                利用者が本商品を利用して第三者に損害を与えた場合、利用者は自己の責任と費用をもって解決するものとし、当社に損害を与えないものとします。
              </li>
            </ol>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>第 25 条（禁止事項）</h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                利用者は、本商品を業として第三者の需要に応ずるために提供してはならないものとします。利用者が本項に違反し、本商品を業として第三者の需要に応ずるために提供した場合、当該第三者による本商品の利用は利用者自身による利用とみなします。
              </li>
              <li>
                利用者は、本商品を使用するにあたり、以下の行為を行ってはならないものとします。
                <ol>
                  <li>
                    他者（当社を含みます。以下、同様とします）の知的財産権その他の権利を侵害する行為
                  </li>
                  <li>他者の財産、プライバシーもしくは肖像権を侵害する行為</li>
                  <li>
                    他者を誹謗中傷し、またはその名誉もしくは信用を毀損する行為
                  </li>
                  <li>
                    詐欺、業務妨害等の犯罪行為またはこれを誘発もしくは扇動する行為
                  </li>
                  <li>
                    わいせつ、児童ポルノ・児童虐待にあたる画像もしくは文書等を送信し、または掲載する行為
                  </li>
                  <li>
                    無限連鎖講（ネズミ講）を開設し、またはこれを勧誘する行為
                  </li>
                  <li>
                    他者のWeb
                    サイト等、本商品により利用しうる情報を改ざんし、または消去する行為
                  </li>
                  <li>
                    他者になりすまして本商品を使用する行為（他の利用者のアカウント情報を不正に使用する行為、偽装するためにメールヘッダ部分に細工を施す行為を含みます）
                  </li>
                  <li>
                    自己のアカウント情報を他者と共有し、または、他者が共有しうる状態に置く行為
                  </li>
                  <li>
                    コンピュータウィルスその他の有害なコンピュータプログラムを送信し、または他者が受信可能な状態のまま放置する行為
                  </li>
                  <li>
                    他者の管理する掲示板等（ネットニュース、メーリングリスト、チャット等を含みます）において、その管理者の意向に反する内容または態様で、宣伝その他の書き込みをする行為
                  </li>
                  <li>
                    受信者の同意を得ることなく、広告宣伝または勧誘のメールを送信する行為
                  </li>
                  <li>
                    受信者の同意を得ることなく、受信者が嫌悪感を抱く、またはそのおそれのあるメール（嫌がらせメール）を送信する行為
                  </li>
                  <li>
                    他者の施設、設備もしくは機器に権限なくアクセスする行為
                  </li>
                  <li>
                    他者が管理するサーバ等に著しく負荷を及ぼす態様で本商品を使用し、またはそれらの運営を妨げる行為
                  </li>
                  <li>
                    その行為が前各号のいずれかに該当することを知りつつ、その行為を助長する態様でリンクをはる行為
                  </li>
                  <li>
                    その他、法令もしくは公序良俗に違反し、または他者の権利を著しく侵害する行為
                  </li>
                  <li>前各号に該当するおそれがあると当社が判断する行為</li>
                  <li>その他、当社が不適切と判断する行為</li>
                </ol>
              </li>
              <li>
                利用者は、音声サービスを利用するにあたり、前項の禁止事項に加えて、以下の行為を行ってはならないものとします。
                <ol>
                  <li>
                    故意に多数の不完了呼（通信の相手先に応答前に発信を取りやめることをいいます）を発生させ、または連続的に多数の呼を発生させるなど、通信のふくそうを生じさせるおそれのある行為
                  </li>
                  <li>
                    第三者または当社に迷惑・不利益を及ぼす行為、音声サービスに支障をきたすおそれのある行為、音声サービスの運営を妨げる行為
                  </li>
                  <li>
                    音声サービスの利用において、本人の同意を得ることなく不特定多数の第三者に対し、自動電話ダイヤリングシステムを用いまたは合成音声もしくは録音音声等を用いて、商業的宣伝や勧誘などの通信を行う行為または商業的宣伝や勧誘などを目的とした回線への発信を誘導する行為
                  </li>
                  <li>
                    音声サービスの利用において、自動電話ダイヤリングシステムを用いまたは合成音声もしくは録音音声等を用いて、第三者が嫌悪感を抱くまたはその恐れのある通信をする行為
                  </li>
                  <li>
                    一方的な発信または機械的な発信等により一定時間内に長時間または多数の通信等を一定期間継続する行為
                  </li>
                  <li>
                    他社着信転送サービス（他の電気通信事業者が有する電話番号を介して他の電話
                  </li>
                  <li>
                    番号に着信させることを主な目的とするサービス）を利用する行為
                  </li>
                  <li>
                    直接的な収益を得る目的で、通信の媒介、転送機能の利用、他社が提供するサービスへの接続、または特定の相手先への大量の通話等を行う行為
                  </li>
                  <li>
                    音声サービスの一般的な利用と著しく異なる利用態様が認められるなどにより、通話以外の目的で利用されているものと当社が判断する行為
                  </li>
                  <li>前各号に該当するおそれがあると当社が判断する行為</li>
                  <li>その他、当社が不適切と判断する行為</li>
                </ol>
              </li>
              <li>
                前二項の規定は、利用者がこれらの禁止事項を行わないよう、当社に情報の監視または削除等の義務を課すものではありません。前二項に定める禁止事項が行われ、当社がこれらの情報の監視または削除等を行わなかったことにより利用者または第三者に損害が発生した場合であっても、当社は一切の責任を負わないものとします
              </li>
              <li>
                利用者が本条第1 項から第3
                項までのいずれかの規定に違反したことにより当社に損害が発生した場合、当社は、利用者に対し、その損害の賠償を請求することができるものとします
              </li>
            </ol>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>
            第 26 条（他のインターネットサービス）
          </h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                利用者は、本商品を利用して当社以外の者が管理、運営する Web
                サイト等のインターネット上のサービス（以下、「他のインターネットサービス」といいます）にアクセスする場合は、第25条（禁止事項）第2
                項または第3
                項に該当する行為を行わないものとします。また、他のインターネットサービスの管理者から当該サービスの利用に係わる注意事項が表示されているときは、利用者はこれを遵守するものとします。
              </li>
              <li>
                当社は、他のインターネットサービスに関し、一切責任を負いません。
              </li>
              <li>
                利用者は、他のインターネットサービスを利用する場合においても、第
                24条（自己責任の原則）が適用されることを承諾します。
              </li>
              <li>
                当社は、利用者が本商品を利用することにより、インターネットに接続された世界中のいずれのサイトにもアクセスできることを保証するものではありません。
              </li>
            </ol>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>
            第 27 条（利用者の設備等にかかる維持責任）
          </h3>
          <div className={classes.agreementDetail}>
            <p>
              利用者が本商品を利用するために必要となる設備については、利用者が自己の費用と責任において維持するものとします。
            </p>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>第 28 条（著作権等）</h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                利用者は、本商品の利用に関して当社が利用者に提供するソフトウェア、マニュアルその他情報（以下、「ソフトウェア等」といいます）（映像、音声、文章等を含む。以下、同様とします）に関する著作権、商標、商号、技術その他に関する一切の権利が、当社または当社に対してソフトウェア等を提供した第三者に帰属するものであることを確認するものとします。
              </li>
              <li>
                利用者は、ソフトウェア等を自己使用の目的のみに利用することができます。利用者は、ソフトウェア等について自己使用以外の目的による複製を行わないものとし、ソフトウェア等をWeb
                サイトに掲載し、また公衆送信を行うこと等により、第三者による複製を行わせてはならないものとします。
              </li>
              <li>
                利用者は、本商品の利用を終了した場合には、速やかにソフトウェア等を消去するものとします。
              </li>
              <li>
                本条の規定に違反して紛争が発生した場合、利用者は、自己の費用と責任において当該紛争を解決するとともに、いかなる場合においても当社に損害を与えないものとします。
              </li>
            </ol>
          </div>
        </div>

        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>
            第 29 条（利用者情報の取扱い）
          </h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                当社は、利用者情報について、善良な管理者としての注意をもって管理します。
              </li>
              <li>
                当社は、利用者情報を以下の目的にのみ利用し、法令に基づいて官公庁から開示を求められた場合を除き、第三者に開示しないものとします
                <ol>
                  <li>
                    携帯音声通信事業者による契約者等の本人確認等及び携帯音声通信役務の不正な利用の防止に関する法律（平成17
                    年法律第31
                    号）（以下、「携帯電話不正利用防止法」といいます）その他法令に定められた不正利用防止の目的
                  </li>
                  <li>
                    青少年が安全に安心してインターネットを利用できる環境の整備等に関する法律（平成20
                    年法律第79号）（以下、「青少年インターネット環境整備法」といいます）その他法令に定められた青少年に対するフィルタリングサービス等提供の目的
                  </li>
                  <li>本商品の利用料金を回収する目的</li>
                  <li>利用者に対するサポートサービスを円滑に提供する目的</li>
                  <li>
                    利用者に対し、本商品の追加または変更のご案内、または緊急連絡の目的で、電子メールまたは郵便等で通知をする目的
                  </li>
                  <li>
                    商品開発等の目的で本商品に関する利用動向を調査し、特定個人の識別が不可能な形式に加工したうえで、その分析結果を自ら利用し、または第三者に提供する目的
                  </li>
                  <li>前各号のほか、利用者から事前の同意を得た目的</li>
                </ol>
              </li>
              <li>
                前項の定めにかかわらず、当社は、以下の各号に該当する場合には、利用者情報を警察機関その他の行政機関に提供することがあります。
                <ol>
                  <li>
                    前項第1 号から第3
                    号の目的のために当社が必要であると判断した場合
                  </li>
                  <li>第6 条（申込み）第2 項なお書に該当する場合</li>
                  <li>
                    第25 条（禁止事項）第2 項または第3
                    項に違反する行為が認められた場合
                  </li>
                </ol>
              </li>
              <li>
                当社は、利用者情報について、利用期間中はもとより、利用契約が終了した日から
                3 年間（第30 条（他の電気通信事業者への情報の通知）第1
                項に定める、料金その他の債務の支払いのない場合は5
                年間）保管するものとします。
              </li>
              <li>
                当社は、契約者確認（携帯電話不正利用防止法第 9
                条で定める契約者確認をいいます。以下、本条において同様とします）を求められたとき、または、これに準ずる事由が発生したことにより当社が契約者確認を行う必要があると認めたときは、当該利用者に対し、契約者確認を行うことがあります。この場合、利用者は、当社の定める期日までに契約者確認に応じるものとします。
              </li>
            </ol>
          </div>
        </div>
        <div className={classes.agreementCard}>
          <h3 className={classes.agreementMainPoint}>
            第 30 条（他の電気通信事業者への情報の通知）
          </h3>
          <div className={classes.agreementDetail}>
            <ol>
              <li>
                利用者は、第 7 条（初期契約解除）、第8
                条（利用者による解約）または第 34
                条（当社による利用契約の解除）の規定に基づき契約を終了した後、現に料金その他の債務の支払いがない場合、または第
                29条（利用者情報の取扱い）第5
                項に定める契約者確認に応じない場合には、当社が、当社以外の電気通信事業者からの請求に基づき、氏名、住所、電話番号、生年月日および支払状況等の情報（利用者を特定するために必要なものおよび支払状況に関するものであって、当社が別に定めるものに限ります）
                を当該事業者に通知することにあらかじめ同意するものとします。
              </li>
              <li>
                利用者は、当社が、国際電気通信事業者等からの請求に基づき、氏名、住所、電話番号および生年月日等の情報を当該事業者に通知することにあらかじめ同意するものとします。
              </li>
              <li>
                前二項の規定によるほか、利用者は、当社が、番号ポータビリティにかかる携帯電話事業者からの請求に基づき、氏名、住所、電話番号および生年月日等の情報（番号ポータビリティにかかる手続きのために必要なものに限ります）を当該事業者に通知することにあらかじめ同意するものとします。
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MethodsOfUtilization;
